import React from "react";
import { Container } from "react-bootstrap";

function GeradorMetaTag() {
  return (
    <Container className="text-left">
      Ferramenta em desenvolvimento...
    </Container>
  );
}

export { GeradorMetaTag };
export default GeradorMetaTag;
